import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "core"
    }}>{`Core`}</h2>
    <ul>
      <li parentName="ul">{`Add flow control mechanism for throttling any types of input`}</li>
      <li parentName="ul">{`Add asynchronous file operations`}</li>
      <li parentName="ul">{`Support OpenSSL engines`}</li>
    </ul>
    <h2 {...{
      "id": "filters"
    }}>{`Filters`}</h2>
    <ul>
      <li parentName="ul">{`New filter tee`}</li>
      <li parentName="ul">{`New filter throttleConcurrency`}</li>
      <li parentName="ul">{`New filter demux (legacy demux/mux filters are renamed to demuxQueue/muxQueue)`}</li>
      <li parentName="ul">{`Filter decodeMQTT to support protocolLevel option`}</li>
      <li parentName="ul">{`Throttle filters support weak references as the account keys`}</li>
    </ul>
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ul>
      <li parentName="ul">{`Support TTL option for algo.Cache`}</li>
      <li parentName="ul">{`New method stats.Metric.valueOf() for querying current metric value`}</li>
      <li parentName="ul">{`Data.from() rejects input types other than strings`}</li>
    </ul>
    <h2 {...{
      "id": "major-bug-fixes"
    }}>{`Major bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a bug where TLS requests could be stuck in the buffer`}</li>
      <li parentName="ul">{`Fixed a dead loop when trying to read folders in codebases`}</li>
      <li parentName="ul">{`Fixed some crashes when null is given to some cypto APIs`}</li>
      <li parentName="ul">{`Fixed a crash when retrieving stats.Histogram sub-metrics`}</li>
      <li parentName="ul">{`Fixed a bug where an HTTP 304 response would lead to forever waiting on the connection`}</li>
      <li parentName="ul">{`Fixed a bug in Data::shift_while() that caused Dubbo decoding failures`}</li>
      <li parentName="ul">{`The size of Data wasn't changed accordingly in some cases when packing`}</li>
    </ul>
    <h2 {...{
      "id": "build"
    }}>{`Build`}</h2>
    <ul>
      <li parentName="ul">{`Support static linking to libc`}</li>
      <li parentName="ul">{`Support linking to external zlib`}</li>
    </ul>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <p>{`Tremendous growth in documentation coverage has been done since the last release in English, Chinese and Japanese.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      